<template>
  <div class="notification">
    <div class="notification-icon">
      <span class="icon-circle-check"></span>
    </div>
    <div class="notification-body">
      <h3 class="notification-title">
        {{ title }}
      </h3>
      <div class="notification-message">
        {{ message }}
      </div>
    </div>
    <mc-button
      clear
      icon="icon-close-cross-exit-quit-delete"
      class="notification-icon-close"
      icon-size="large"
      @click="notificationStore.removeNotification(id)"
    >
    </mc-button>
  </div>
</template>

<script setup lang="ts">
import { useNotificationStore } from "@/store/notificationStore";
import McButton from "../McButton.vue";
import { onMounted } from "vue";

const notificationStore = useNotificationStore();
const NOTIFICATION_TIMEOUT = 5000;
interface Props {
    title: string;
    message: string;
    id: string;
}
const { id } = defineProps<Props>();
onMounted(() => {
    setTimeout(() => {
      notificationStore.removeNotification(id);
    }, NOTIFICATION_TIMEOUT);
});

</script>

<style scoped lang="scss">

.notification {
    
    max-width: 520px;
    width: 100%;
    background-color: #E6F6EB;
    box-shadow: 0px 8px 40px 0px #0000000D, 0px 12px 32px -16px #0000000E;
    border-radius: 8px;
    border: 1px solid #DBD8E0;
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 8px;
    &-body {
        flex-grow: 1;
    }
    &-title {
        font-family: Nunito;
        font-size: 18px;
        font-weight: 600;
        line-height: 24.55px;
        text-align: left;
        color: #193B2D;
        margin: 0;
    }
    &-message {
        font-family: Nunito;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #218358;
        margin: 0;
    }
    &-icon{
        margin-left: 10px;
        span{
            color: var(--icon-success-color-subtle-light);
            font-size: 20px;
        }
        &-close {
            --text-colour-text-contrast: var(--text-color-neutral-subtle-light);
            --button-main-color: #20003618;
        }
    }
}

</style>