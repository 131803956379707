<template>
  <McDialog
    ref="unsavedChangesDialog"
    class="dialog-test"
    :is-internal-actions-needed="false"
  >
    <template #title>
      <section class="title-wrapper">
        <span class="icon-triangle-exclamation-warning-alert"></span>
        <h3 class="title">
          You have unsaved changes
        </h3>
      </section>
    </template>
    <div class="warning-message">
      {{ warningMessage }}
    </div>

    <template #actions>
      <McButton
        class="discard-changes dialog-button"
        @click="onDiscardChanges"
      >
        Discard Changes
      </McButton>
      <McButton
        class="continue-editing dialog-button"
        @click="onContinueEditing"
      >
        Continue Editing
      </McButton>
    </template>
  </McDialog>
</template>

<script lang="ts" setup>
import McDialog from "@/components/ui/McDialog/McDialog.vue";
import McButton from "./McButton.vue";
import { onMounted, ref, toRef, watch } from "vue";
import { useUnsavedChangesStore } from "@/store/unsavedChangesStore";

const showDialog = ref(false);
const emit = defineEmits(['discard-changes', 'continue-editing']);
const unsavedChangesDialog = ref();
const unsavedChangesStore = useUnsavedChangesStore();
const warningMessage = toRef(unsavedChangesStore.warningMessage);

const onContinueEditing = () => {
  unsavedChangesStore.handleDialogActions(true);
  showDialog.value = false;
};

const onDiscardChanges = () => {
  unsavedChangesStore.handleDialogActions(false);
  showDialog.value = false;
};

onMounted(() => {
  unsavedChangesStore.$onAction(({ name })=> {
    if (name === "showUnsavedChangesDialog") {
      warningMessage.value = unsavedChangesStore.warningMessage;
      showDialog.value = true;
    }
  });
});

watch(showDialog, (newValue) => {
  newValue ? unsavedChangesDialog.value.openDialog() : unsavedChangesDialog.value.closeDialog();
});
</script>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  gap: 18px;
  align-items: center;

  .icon-triangle-exclamation-warning-alert {
    color: var(--background-color-danger-solid-light);
    font-size: 28px;
  }

  .title {
    color: var(--text-color-neutral-light);
    font-size: 21px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
  }
}

.warning-message {
  margin-top: 16px;
  color: var(--text-color-neutral-subtle-light);
}

.dialog-button {
  --button-padding: 11px 20px 11px 20px;

  &.discard-changes {
    --button-main-color: var(--background-color-danger-light);
    --text-colour-text-contrast: var(--text-color-danger-subtle-light);
  }

  &.continue-editing {
    --button-main-color: var(--background-color-success-solid-light);
    --text-colour-text-contrast: var(--text-color-contrast-light);
  }
}
</style>